::selection {
  color: #fff;
  background: #f68d2e;
}
a,
.btn-link {
  color: #f0b144;
}
.text-green,
.text-info,
.text-success,
.text-danger,
.text-primary,
.text-warning,
.mail-star.active {
  color: #f68d2e !important;
}
.badge-default {
  background: #747879;
  color: #f5f5f7;
}
.loader {
  color: #f68d2e;
}
.badge-primary {
  background-color: #f68d2e;
}
.btn-primary {
  background: #f68d2e;
  color: #fff;
  border-color: #f68d2e;
}
.btn-info {
  background-color: #f0b144;
  border-color: #f0b144;
}
.nav-tabs .nav-link.active {
  border-color: #f68d2e;
  color: #f68d2e;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 111, 9, 0.5);
}
.dropify-wrapper,
.btn-primary {
  border: 0;
}
.bg-primary,
.bg-info,
.bg-warning,
.btn-success {
  background-color: #f68d2e !important;
  border: transparent;
}
.bg-success,
.bg-danger,
.badge-success,
.progress-bar,
.btn-danger,
.btn-warning {
  background-color: #f0b144;
  border: transparent;
}
.btn-outline-primary {
  color: #f68d2e;
  border-color: #f68d2e;
}
.btn-outline-primary:hover {
  background-color: #f68d2e;
  border-color: #f68d2e;
}
.custom-control-input:checked ~ .custom-control-label::before,
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before,
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  border-color: #f68d2e;
  background-color: #f68d2e;
}
.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #f68d2e;
}
.selectgroup-input:checked + .selectgroup-button {
  border-color: #f68d2e;
  color: #f68d2e;
  background: rgba(0, 70, 96, 0.2);
}
.imagecheck-figure:before {
  color: #f0b144 !important;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active,
.wizard > .actions a,
.wizard > .actions a:hover {
  background: #f68d2e;
}
.wizard > .steps .done a,
.wizard > .steps .done a:hover {
  background: rgba(0, 70, 96, 0.2);
  color: #f68d2e;
}
.list-group-item,
.modal-content {
  background-color: #fff;
}
.table th {
  color: #747879;
}
.table.table-custom td,
.table.table-custom th {
  background: #f5f5f7;
}
.table.table_custom tr {
  background: #fff;
}
.right_chat li.online .status {
  background: #f68d2e;
}
.bg-blue,
.bg-azure,
.bg-indigo,
.bg-purple,
.bg-red,
.bg-orange {
  background-color: #f68d2e !important;
}
.bg-pink,
.bg-yellow,
.bg-lime,
.bg-green,
.bg-teal,
.bg-cyan {
  background-color: #f0b144 !important;
}
.bg-light-blue,
.bg-light-azure,
.bg-light-indigo,
.bg-light-lime,
.bg-light-green,
.bg-light-teal {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.bg-light-pink,
.bg-light-red,
.bg-light-orange,
.bg-light-yellow,
.bg-light-cyan {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
.bg-light-purple {
  background-color: rgba(254, 210, 132, 0.1);
  color: #fed284;
}
.bg-light-gray {
  background-color: rgba(102, 106, 109, 0.1);
  color: #666a6d;
}
.avatar.avatar-blue {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-azure {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-indigo {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-purple {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-pink {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-red {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-orange {
  background-color: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.avatar.avatar-yellow {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
.avatar.avatar-lime {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
.avatar.avatar-green {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
.avatar.avatar-teal {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
.avatar.avatar-cyan {
  background-color: rgba(240, 177, 68, 0.1);
  color: #f0b144;
}
body {
  background-color: #f5f5f7;
  color: #113f50;
}
.page-header {
  border-color: #e6e9ed;
}
.page-header .right .nav-pills .nav-link {
  color: #113f50;
}
#header_top .brand-logo {
  background: #fed284;
  width: 35px;
}
.header {
  background: #f68d2e;
}
.header .nav-tabs .nav-link {
  color: #fff;
}
.header .nav-tabs .nav-link.active {
  border-color: #fff;
}
.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item.active {
  color: #f68d2e;
}
.page .section-white,
.page .section-body {
  background: #f5f5f7;
}
#header_top .nav-link {
  color: #113f50;
}
.header_top.dark {
  background-color: #f68d2e !important;
}
.header_top.dark .nav-link {
  color: #f5f5f7 !important;
}
.card {
  background-color: #fff;
}
.card .card-options a {
  color: #f68d2e;
}
.card .card-options a.btn {
  color: #fff;
}
.card.card-fullscreen {
  background-color: #fff !important;
}
.metismenu .g_heading {
  color: rgba(255, 255, 255, 0.3);
}
.metismenu a {
  color: #113f50;
}
.metismenu a:hover {
  color: #f68d2e;
  border-color: #f68d2e;
}
.metismenu .active > a {
  color: #f68d2e;
  border-color: #f68d2e;
}
.metismenu .active ul .active a {
  color: #f68d2e;
  background: transparent;
}
.metismenu.grid > li.active > a,
.metismenu.grid > li > a:hover {
  background: rgba(0, 70, 96, 0.1);
  color: #f68d2e;
}
.inbox .detail {
  background: #fff;
}
.file_folder a {
  background: #fff;
  border-color: #f5f5f7;
}
.auth .auth_left {
  background: #fff;
}
.gender_overview {
  background: #f5f5f7;
}
.table-calendar-link {
  background: #f68d2e;
}
.table-calendar-link:hover {
  background: #f68d2e;
}
.table-calendar-link::before {
  background: #f68d2e;
}
.theme_div {
  background: #fff;
}
.ribbon .ribbon-box.green {
  background: #f68d2e;
}
.ribbon .ribbon-box.green::before {
  border-color: #f68d2e;
  border-right-color: transparent;
}
.ribbon .ribbon-box.orange {
  background: #f68d2e;
}
.ribbon .ribbon-box.orange::before {
  border-color: #f68d2e;
  border-right-color: transparent;
}
.ribbon .ribbon-box.pink {
  background: #f0b144;
}
.ribbon .ribbon-box.pink::before {
  border-color: #f0b144;
  border-right-color: transparent;
}
.ribbon .ribbon-box.indigo {
  background: #fed284;
}
.ribbon .ribbon-box.indigo::before {
  border-color: #fed284;
  border-right-color: transparent;
}
.text-orange {
  color: #fed284 !important;
}
.tag-info {
  background: #f68d2e;
}
.tag-success,
.tag-green {
  background: #f68d2e;
}
.tag-orange {
  background: #f0b144;
}
.tag-danger {
  background: #f0b144;
}
.bg-success {
  background: #f68d2e !important;
}
.bg-danger {
  background-color: #f0b144 !important;
}
.auth .auth_left {
  background: #f68d2e;
}
.auth .auth_left .card {
  padding: 20px;
  right: -150px;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .auth .auth_left .card {
    right: -50px;
  }
}
@media only screen and (max-width: 992px) {
  .auth .auth_left .card {
    right: auto;
  }
}
.page-item .page-link {
  color: #f0b144;
}
.page-item.active .page-link {
  background-color: #f0b144;
  border-color: #f0b144;
}
#left-sidebar {
  background-color: #fff;
}
.dark-mode .btn {
  color: #417082;
}
.dark-mode .metismenu.grid > li > a {
  border-color: rgba(255, 255, 255, 0.2);
}
.dark-mode.sidebar_dark #left-sidebar {
  background-color: #f68d2e !important;
}
.dark-mode.sidebar_dark .sidebar .metismenu .active > a {
  color: #f68d2e;
}
.dark-mode.sidebar_dark .sidebar .metismenu .active ul .active a {
  color: #f68d2e;
}
.dark-mode.sidebar_dark .sidebar .metismenu a {
  color: #fff;
}
.dark-mode .nav-tabs .nav-link.active {
  color: #fed284;
}
.top_dark {
  background-color: #f68d2e !important;
}
.sidebar_dark #left-sidebar {
  background-color: #f68d2e !important;
}
.sidebar_dark #header_top .nav-link {
  color: #f5f5f7 !important;
}
.sidebar_dark .sidebar .nav-tabs {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar_dark .sidebar .nav-tabs .nav-link.active {
  border-color: #fff;
  color: #fff;
}
.sidebar_dark .sidebar .metismenu .active > a {
  color: #fed284;
}
.sidebar_dark .sidebar .metismenu .active ul .active a {
  color: #fed284;
}
.iconcolor #header_top .nav-link {
  color: #fed284 !important;
}
.gradient .custom-switch-input:checked ~ .custom-switch-indicator {
  background: linear-gradient(45deg, #f68d2e, #f0b144) !important;
}
.gradient .metismenu.grid > li.active > a,
.gradient .metismenu.grid > li > a:hover {
  background: linear-gradient(45deg, #f68d2e, #f68d2e) !important;
  color: #fff;
  border: 0;
}
.gradient .btn-primary {
  background: linear-gradient(45deg, #f68d2e, #f0b144) !important;
  border: 0;
}
.gradient .btn-dark {
  background: linear-gradient(45deg, #808488, #333537) !important;
}
.gradient .bg-success,
.gradient .bg-danger,
.gradient .badge-success,
.gradient .progress-bar,
.gradient .btn-danger {
  background: linear-gradient(45deg, #f68d2e, #f0b144) !important;
}
.gradient.sidebar_dark #left-sidebar {
  background: linear-gradient(45deg, #f68d2e, #f0b144) !important;
}
@media only screen and (min-width: 1200px) {
  .header_top > .container {
    border-right: 1px solid rgba(255, 255, 255, 0.07);
  }
}
@media only screen and (max-width: 1200px) {
  #header_top {
    background: #f68d2e;
  }
}
