.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}

div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
div.font-roboto {
  font-family: "Robot", sans-serif;
}
div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
div.rtl {
  direction: rtl !important;
  text-align: right;
}
.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}
.marginTop {
  margin-top: 7.2% !important;
}

/* side menu */
#left-sidebar {
  z-index: 1;
}

.metismenu ul a {
  padding-left: 0;
}
/* end of side menu */

/* input form */
.input-group-addon {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.input-group-addon i::before {
  font-size: 1rem !important;
}
/* end of input form */

/* reset password prompt */
.prompt-container {
  margin: 0 -20px;
  margin-bottom: -20px !important;
  border-radius: 8px;
}

.prompt-success {
  border: solid 1px #27ae60;
  background-color: #27ae60;
}

.prompt-fail {
  border: solid 1px #d63031;
  background-color: #d63031;
}

.prompt-title {
  color: white;
  padding: 0.5rem 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: 0;
}

.prompt-subtitle {
  padding: 0.5rem 1rem;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.prompt-container .btn-container {
  display: flex;
  justify-content: flex-end;
}

.prompt-container .btn-success {
  background-color: #27ae60 !important;
  color: white;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  margin-left: auto !important;
  border-color: #27ae60 !important;
}

.prompt-container .btn-success:hover {
  background-color: #1f8b4c !important;
  border-color: #1f8b4c !important;
}

.prompt-container .btn-success:active {
  background-color: #1b7943 !important;
  border-color: #1b7943 !important;
}
/* end of reset password prompt */
